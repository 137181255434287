import React from "react";
import "../styles/PackListing.css";
import { IoIosArrowForward } from "react-icons/io";
import CustomButton from "../components/CustomButton/CustomButton";
import config from "../utils/config";
import PopularPackCard from "../components/CardsComponent/PackCard/PopularPackCard";
import AllPackCard from "../components/CardsComponent/PackCard/AllPackCard";

const PackListing = () => {
  const offerCards = [
    {
      image: `${config.s3imgUrl}trackimages/sampleyrf.png`,
      title: "Cinematic Shots",
      description: "Don't miss out on this trend-setting opportunity!....",
      buttonText: "Explore Packs",
    },
    {
      image: `${config.s3imgUrl}trackimages/samplepopularsong.png`,
      title: "Indian Festivals",
      description: "Don't miss out on this trend-setting opportunity...",
      buttonText: "Explore Packs",
    },
  ];

  const packCards = [
    {
      image: `${config.s3imgUrl}trackimages/sampleimage.png`,
      title: "Happy Diwali",
      buttonText: "Explore Tracks",
      buttonLink: "#",
      totalTracks: 10,
    },
    {
      image: `${config.s3imgUrl}trackimages/sampleimage.png`,
      title: "Happy Diwali",
      buttonText: "Explore Tracks",
      buttonLink: "#",
      totalTracks: 12,
    },
    {
      image: `${config.s3imgUrl}trackimages/sampleimage.png`,
      title: "Happy Diwali",
      buttonText: "Explore Tracks",
      buttonLink: "#",
      totalTracks: 14,
    },
  ];

  return (
    <div className="pack-listing-container">
      {/* packs page description */}
      <div className="pack-listing-description outer">
        <h1>Shop By Pack</h1>
        <h2>
          Don't miss out on this trend-setting opportunity! For a limited time,
          you can Don't miss out on this trend-setting opportunity! For a
          limited time, you can.....
        </h2>
      </div>
      {/* top packs card section */}
      <div className="toppack-container outer">
        {offerCards.map((offer, index) => (
          <div
            key={index}
            style={
              {
                //   backgroundImage: `url(${offer.image})`,
              }
            }
            className="toppack-card-img"
          >
            <div className="toppack-card-title">
              <h1>{offer.title}</h1>
            </div>
            <div className="toppack-card-description">
              <h2>{offer.description}</h2>
            </div>
            <div>
              <CustomButton
                text={offer.buttonText}
                backgroundColor="#fff"
                borderRadius="30px"
                borderColor="none"
                width="182px"
                height="37px"
                textColor="#C6349B"
                iconColor="#C6349B"
                fontSize="16px"
                fontFamily="Raleway"
                fontWeight="bold"
                icon={<IoIosArrowForward />}
                justifyContent="space-between"
              />
            </div>
          </div>
        ))}
      </div>
      {/* popular packs */}
      <PopularPackCard />
      {/* all packs section */}
      <AllPackCard />
    </div>
  );
};

export default PackListing;

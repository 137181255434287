import axios from "axios";
import { urls } from "../utils/AppManager";
import config from "../utils/config";

export const getAllTrack = async (page = 1) => {
  const limit = 20;
  const url = `${config.baseUrl}${urls.getTrack}?page=${page}&limit=${limit}`;
  try {
    const res = await axios.get(url);
    return res.data;
  } catch (error) {
    console.error("Error fetching tracks:", error);
    return { data: [] };
  }
};

export const getTrack = async (trackCode) => {
  const url = `${config.baseUrl}${urls.getTrack}/${trackCode}`;
  // console.log(url);
  const res = await axios
    .get(url)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error, "error");
    });
  return res;
};

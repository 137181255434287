import React from "react";
import "./PopularPackCard.css";
import { FaRegHeart } from "react-icons/fa";
import { RiShoppingCart2Line } from "react-icons/ri";
import { IoIosArrowForward } from "react-icons/io";
import CustomButton from "../../CustomButton/CustomButton";
import config from "../../../utils/config";
const PopularPackCard = () => {
  const packCards = [
    {
      image: `${config.s3imgUrl}trackimages/sampleimage.png`,
      title: "Happy Diwali",
      buttonText: "Explore Tracks",
      buttonLink: "#",
      totalTracks: 10,
    },
    {
      image: `${config.s3imgUrl}trackimages/sampleimage.png`,
      title: "Happy Diwali",
      buttonText: "Explore Tracks",
      buttonLink: "#",
      totalTracks: 12,
    },
    {
      image: `${config.s3imgUrl}trackimages/sampleimage.png`,
      title: "Happy Diwali",
      buttonText: "Explore Tracks",
      buttonLink: "#",
      totalTracks: 14,
    },
  ];

  return (
    <div>
      <div className="packs-container outer-full">
        <div className="inner">
          <h1>Popular Packs</h1>
          <div className="packs-description-view">
            <h2>On your first Instagram Trending Track Purchase </h2>
            {/* <div className="packs-viewall">
              <p>View All</p>
              <p>
                <IoIosArrowForward color="#373737" />
              </p>
            </div> */}
          </div>
          <div className="inner">
            <div className="packs-cards-container">
              {packCards.map((pack, index) => (
                <div key={index} className="pack-card">
                  <h1>{pack.title}</h1>
                  <div
                    className="pack-card-img"
                    style={{
                      backgroundImage: `url(${pack.image})`,
                    }}
                  >
                    <div className="card-like-info">
                      <div className="like-icon">
                        <FaRegHeart size={24} color="#fff" />
                      </div>
                      <div className="card-track-total">
                        <p>{pack.totalTracks}</p>
                        <span>TRACKS</span>
                      </div>
                    </div>
                  </div>
                  <div className="pack-price-button">
                    <div className="pack-price-container">
                      <div className="pack-discount-price">
                        <div className="pack-discount-percentage">-50%</div>
                        <div className="pack-discount-amount">
                          <span>₹</span>
                          <p>49,999</p>
                        </div>
                      </div>
                      <div className="pack-cost-price">
                        <p>Cost:</p>
                        <span>₹79,000</span>
                      </div>
                    </div>
                    <div className="explore-track-button">
                      <CustomButton
                        text={pack.buttonText}
                        backgroundColor="#fff"
                        borderRadius="50px"
                        borderColor="1px solid #C6349B"
                        width="179px"
                        height="44px"
                        textColor="#C6349B"
                        iconColor="#C6349B"
                        fontSize="15px"
                        fontFamily="Inter"
                        fontWeight="bold"
                        icon={<IoIosArrowForward />}
                        justifyContent="space-between"
                      />
                      <CustomButton
                        icon={<RiShoppingCart2Line />}
                        isIconOnly={true}
                        backgroundColor="#C6349B"
                        iconColor="#fff"
                        borderRadius="50px"
                        height="46px"
                        width="46px"
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopularPackCard;

import React from "react";
import "../styles/TrackListing.css";
import config from "../utils/config";
import { FaPlay } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";
import CustomButton from "../components/CustomButton/CustomButton";
import { RiShoppingCart2Line } from "react-icons/ri";
import AllTrackCards from "../components/CardsComponent/TrackCard/AllTrackCards";

const TrackListing = () => {
  const trackCards = [
    {
      image: `${config.s3imgUrl}trackimages/sampleimage.png`,
      title: "New on Hoopr Smash",
      description: "Don’t miss out on this trend-setting opportunity!",
      buttonText: "Explore Track",
      buttonLink: "#",
      width: "661px",
    },
    {
      image: `${config.s3imgUrl}trackimages/sampleimage.png`,
      title: "Mere Mehboob",
      description: "Don’t miss out on this trend-setting opportunity!",
      buttonText: "Explore Track",
      buttonLink: "#",
      with: "322px",
    },
    {
      image: `${config.s3imgUrl}trackimages/sampleimage.png`,
      title: "Jo Tum Mere Ho",
      description: "Don’t miss out on this trend-setting opportunity!",
      buttonText: "Explore Track",
      buttonLink: "#",
      with: "322px",
    },
  ];

  const popularTracks = [
    {
      image: `${config.s3imgUrl}trackimages/sampleimage.png`,
      title: "Mere Mehboob",
      description: "Sachin Jigar",
      buttonText: "Explore",
      buttonLink: "#",
      discountPercent: "50%",
      costPrice: "79,000",
      sellingPrice: "19,999",
    },
    {
      image: `${config.s3imgUrl}trackimages/sampleimage.png`,
      title: "Mere Mehboob",
      description: "Sachin Jigar",
      buttonText: "Explore",
      buttonLink: "#",
      discountPercent: "50%",
      costPrice: "79,000",
      sellingPrice: "19,999",
    },
    {
      image: `${config.s3imgUrl}trackimages/sampleimage.png`,
      title: "Mere Mehboob",
      description: "Sachin Jigar",
      buttonText: "Explore",
      buttonLink: "#",
      discountPercent: "50%",
      costPrice: "79,000",
      sellingPrice: "19,999",
    },
    {
      image: `${config.s3imgUrl}trackimages/sampleimage.png`,
      title: "Mere Mehboob",
      description: "Sachin Jigar",
      buttonText: "Explore",
      buttonLink: "#",
      discountPercent: "50%",
      costPrice: "79,000",
      sellingPrice: "19,999",
    },
    {
      image: `${config.s3imgUrl}trackimages/sampleimage.png`,
      title: "Mere Mehboob",
      description: "Sachin Jigar",
      buttonText: "Explore",
      buttonLink: "#",
      discountPercent: "50%",
      costPrice: "79,000",
      sellingPrice: "19,999",
    },
  ];

  return (
    <div className="listing-container">
      <div className="track-listing-description outer">
        <h1>Shop By Track</h1>
        <h2>
          Discover the perfect track for your project. Browse our extensive
          library of premium Bollywood music tracks, categorized by mood, genre,
          and budget.
        </h2>
      </div>
      {/* track card container */}
      {/* Map over trackCards to render each track card */}
      <div className="track-cards-container inner">
        {trackCards.map((card, index) => (
          <div
            key={index}
            style={{
              //   backgroundImage: `url(${card.image})`,
              minWidth: card.width,
            }}
            className="track-listingcard"
          >
            <div className="text-overlay">
              <div className="card-play-button">
                <FaPlay size={24} color="#C6349B" />
              </div>
              <div className="card-description">
                <h1>{card.title}</h1>
                <h2>{card.description}</h2>
                <CustomButton
                  text={card.buttonText}
                  backgroundColor="#fff"
                  borderRadius="50px"
                  borderColor="none"
                  width="182px"
                  textColor="#C6349B"
                  fontSize="16px"
                  fontFamily="Raleway"
                  fontWeight="bold"
                  iconColor="#C6349B"
                  icon={<IoIosArrowForward />}
                  justifyContent="space-between"
                />
              </div>
            </div>
          </div>
        ))}
      </div>
      {/* popular track cards container */}
      <div className="popular-track-cards-container outer-full">
        <div className="inner">
          <h1>Popular Tracks</h1>
          <div className="popular-track-cards">
            {popularTracks.map((track, index) => (
              <div key={index} className="popular-track-card">
                <div
                  className="popular-track-card-image"
                  style={
                    {
                      //   backgroundImage: `url(${track.image})`,
                    }
                  }
                ></div>
                <div className="popular-track-card-text">
                  <div className="popular-track-title">
                    <h1>{track.title}</h1>
                    <h2>{track.description}</h2>
                  </div>
                  <div className="popular-track-price-container">
                    <div className="popular-track-discount-price">
                      <div className="popular-track-discount-amount">
                        <span>₹</span>
                        <p>19,999</p>
                      </div>
                      <div className="popular-track-discount-percentage">
                        50% Off
                      </div>
                    </div>
                    <div className="popular-track-cost-price">
                      <span>₹79,000</span>
                    </div>
                  </div>
                  <div className="popular-explore-track-button">
                    <div>
                      <CustomButton
                        text={track.buttonText}
                        backgroundColor="#fff"
                        borderRadius="50px"
                        borderColor="1px solid #CCCCCC"
                        width="186px"
                        height="37px"
                        textColor="#212328"
                        iconColor="#212328"
                        fontSize="16px"
                        fontFamily="Inter"
                        fontWeight="550"
                        icon={<IoIosArrowForward />}
                        justifyContent="space-between"
                      />
                    </div>
                    <div>
                      <CustomButton
                        icon={<RiShoppingCart2Line />}
                        isIconOnly={true}
                        backgroundColor="#272727"
                        iconColor="#fff"
                        borderRadius="50px"
                        height="37px"
                        width="37px"
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* all songs container */}
      <AllTrackCards />
    </div>
  );
};

export default TrackListing;

import React from "react";
import {
  FaFacebook,
  FaEnvelope,
  FaInstagram,
  FaLinkedin,
  FaYoutube,
} from "react-icons/fa";
import "../styles/Footer.css";
import config from "../utils/config";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        {/* Left Section */}
        <div className="footer-left">
          <img
            src={config.s3imgUrl + "icons/smashlogo.png"}
            alt="Hoopr Smash Logo"
            className="footer-logo-img"
          />
          <p className="footer-description">
            Participate now and elevate your musical journey with our music
            competitions designed for singers, producers, and other talented
            artists!
          </p>
          <div className="footer-social">
            <a href="#" className="footer-icon">
              <FaFacebook />
            </a>
            <a href="#" className="footer-icon">
              <FaEnvelope />
            </a>
            <a href="#" className="footer-icon">
              <FaInstagram />
            </a>
            <a href="#" className="footer-icon">
              <FaLinkedin />
            </a>
            <a href="#" className="footer-icon">
              <FaYoutube />
            </a>
          </div>
        </div>

        {/* Links Section */}
        <div className="footer-links">
          <div className="footer-column">
            <h4 className="footer-heading">Explore</h4>
            <ul>
              <li>
                <a href="#">About Hoopr</a>
              </li>
              <li>
                <a href="#">Our Pricing & Plans</a>
              </li>
              <li>
                <a href="#">Categories</a>
              </li>
              <li>
                <a href="#">SmashHits</a>
              </li>
              <li>
                <a href="#">Occasions</a>
              </li>
              <li>
                <a href="#">Genres</a>
              </li>
            </ul>
          </div>
          <div className="footer-column">
            <h4 className="footer-heading">License & User Terms</h4>
            <ul>
              <li>
                <a href="#">User Guidelines</a>
              </li>
              <li>
                <a href="#">License Terms</a>
              </li>
              <li>
                <a href="#">Terms & Conditions</a>
              </li>
              <li>
                <a href="#">Privacy Policy</a>
              </li>
            </ul>
          </div>
          <div className="footer-column">
            <h4 className="footer-heading">Help</h4>
            <ul>
              <li>
                <a href="#">FAQs</a>
              </li>
              <li>
                <a href="#">Customer Support</a>
              </li>
              <li>
                <a href="#">Knowledge Base</a>
              </li>
              <li>
                <a href="#">Request a Song</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import React, { useState, useEffect, useRef } from "react";
import "../styles/TrackPage.css";
import config from "../utils/config";
import { FaPlay, FaPause } from "react-icons/fa";
import CustomButton from "../components/CustomButton/CustomButton";
import { FaRegHeart } from "react-icons/fa";
import { FiShare2 } from "react-icons/fi";
import { BiAddToQueue } from "react-icons/bi";
import { IoIosArrowForward } from "react-icons/io";
import { RiShoppingCart2Line } from "react-icons/ri";
import {
  // IoIosArrowUp,
  IoIosArrowDown,
  IoMdCheckmark,
  IoIosClose,
} from "react-icons/io";
import { useParams } from "react-router-dom";
import { getTrack } from "../service/TrackListingService";

const TrackPage = () => {
  const [openCards, setOpenCards] = useState([true, false, false]); // state to manage the cards opening state
  const [trackData, setTrackData] = useState([]); // state to hold the fetched track data
  const [isPlaying, setIsPlaying] = useState(false); // State to manage play/pause
  const audioRef = useRef(null); // Ref for the audio element

  const { trackCode } = useParams(); // gettign the data from navigated route

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getTrack(trackCode);
        const data = response.data || [];
        console.log(data, "data");

        setTrackData(data.track); // Set the track object in the state
        // console.log(data, "trackData");
      } catch (error) {
        console.error("Error fetching track data:", error);
      }
    };
    fetchData();
  }, [trackCode]);

  const toggleContainer = (index) => {
    setOpenCards((prevOpenCards) => {
      // Create a new array with the updated open states
      const newOpenCards = prevOpenCards.map((isOpen, i) =>
        i === index ? !isOpen : false
      );
      return newOpenCards;
    });
  };

  const aboutSong = [
    {
      title: "About",
      description: trackData.description || "Description not available",
    },
    {
      title: "Song Credit",
      description: `From "Bad Newz" by Javed Akhtar, Udit Narayan, Alka Yagnik, Lijo George-Dj Chetas, Anu Malik ℗ 2024 Saregama India Ltd`,
    },
    {
      title: "Type",
      description: `Instrumental`,
    },
    {
      title: "Category",
      pills: ["Moods", "Moods", "Moods"],
    },
    {
      title: "Genres",
      pills: ["Genres", "Genres", "Genres"],
    },
    {
      title: "Events",
      pills: ["Acoustic Drums", "Guitar", "Church Bells", "Claps"],
    },
    {
      title: "Language",
      pills: ["English", "Hindi"],
    },
  ];

  const packCards = [
    {
      image: `${config.s3imgUrl}trackimages/sampleimage.png`,
      title: "Happy Diwali",
      buttonText: "Explore Tracks",
      buttonLink: "#",
      totalTracks: 10,
    },
    {
      image: `${config.s3imgUrl}trackimages/sampleimage.png`,
      title: "Happy Diwali",
      buttonText: "Explore Tracks",
      buttonLink: "#",
      totalTracks: 12,
    },
    {
      image: `${config.s3imgUrl}trackimages/sampleimage.png`,
      title: "Happy Diwali",
      buttonText: "Explore Tracks",
      buttonLink: "#",
      totalTracks: 14,
    },
  ];

  const similarTracks = [
    {
      image: `${config.s3imgUrl}trackimages/sampleimage.png`,
      title: "Mere Mehboob",
      description: "Sachin Jigar",
      buttonText: "Explore",
      buttonLink: "#",
      discountPercent: "50%",
      costPrice: "79,000",
      sellingPrice: "19,999",
    },
    {
      image: `${config.s3imgUrl}trackimages/sampleimage.png`,
      title: "Mere Mehboob",
      description: "Sachin Jigar",
      buttonText: "Explore",
      buttonLink: "#",
      discountPercent: "50%",
      costPrice: "79,000",
      sellingPrice: "19,999",
    },
    {
      image: `${config.s3imgUrl}trackimages/sampleimage.png`,
      title: "Mere Mehboob",
      description: "Sachin Jigar",
      buttonText: "Explore",
      buttonLink: "#",
      discountPercent: "50%",
      costPrice: "79,000",
      sellingPrice: "19,999",
    },
    {
      image: `${config.s3imgUrl}trackimages/sampleimage.png`,
      title: "Mere Mehboob",
      description: "Sachin Jigar",
      buttonText: "Explore",
      buttonLink: "#",
      discountPercent: "50%",
      costPrice: "79,000",
      sellingPrice: "19,999",
    },
    {
      image: `${config.s3imgUrl}trackimages/sampleimage.png`,
      title: "Mere Mehboob",
      description: "Sachin Jigar",
      buttonText: "Explore",
      buttonLink: "#",
      discountPercent: "50%",
      costPrice: "79,000",
      sellingPrice: "19,999",
    },
  ];

  const tracksBenefits = [
    {
      image: `${config.s3imgUrl}icons/immediatedownload.png`,
      title: "Immediate Downlaod",
    },
    {
      image: `${config.s3imgUrl}icons/copyrightshield.png`,
      title: "100% Copyright Safe",
    },
    {
      image: `${config.s3imgUrl}icons/copyright.png`,
      title: "Zero-claims Guarantee",
    },
    {
      image: `${config.s3imgUrl}icons/globalnetwork.png`,
      title: "Worldwide Clearance",
    },
    {
      image: `${config.s3imgUrl}icons/chat.png`,
      title: "Dedicated Support Team",
    },
  ];

  const pricingBuyPackCards = [
    {
      image: `${config.s3imgUrl}trackimages/sampleimage.png`,
      title: "Happy Diwali",
      buttonText: "Explore Tracks",
      buttonLink: "#",
      totalTracks: 10,
    },
    {
      image: `${config.s3imgUrl}trackimages/sampleimage.png`,
      title: "Happy Diwali",
      buttonText: "Explore Tracks",
      buttonLink: "#",
      totalTracks: 12,
    },
  ];

  const shortPros = {
    pros: [
      "3 use instances allowed",
      "Quick clearance on submission of usage links",
      "Clearance only for IG Reels and YT Shorts",
      "Publish content within license validity duration",
    ],
    cons: [
      "No clearance for long form content, OTT and broadcast",
      "No clearance for DVC and paid ads",
      "No clearance for influencer campaigns",
    ],
  };

  const togglePlay = () => {
    console.log("click", trackData.sourceLink);

    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const getDirectLink = (sourceLink) => {
    if (!sourceLink) return "";
    // Convert Dropbox link to direct link
    return sourceLink
      .replace("www.dropbox.com", "dl.dropboxusercontent.com")
      .replace("?dl=0", "");
  };

  return (
    <div className="outer-full">
      <div className="outer-full trackDetails">
        {/* track name and details section */}
        <div className="inner mbot">
          <div className="track-artist-info">
            <div className="track-details-container">
              {/* track image */}
              <div
                className="track-image"
                style={{
                  backgroundImage: `url(${trackData.imageUrl})`,
                }}
              >
                {/* card play button */}
                <div>
                  <CustomButton
                    isIconOnly={true}
                    backgroundColor="#fff"
                    borderRadius="50px"
                    iconColor="#000"
                    icon={isPlaying ? <FaPause /> : <FaPlay />}
                    width="52px"
                    height="49px"
                    onClick={togglePlay}
                  />
                </div>
              </div>
              {/* Hidden audio element */}
              <audio
                ref={audioRef}
                src={getDirectLink(trackData.link)}
                style={{ display: "none" }} // Hides the audio element
              />
              {/* track and artist details */}
              <div className="track-details">
                <div className="track-names">
                  <h1>{trackData.name}</h1>
                  <h2>
                    {trackData.tracksArtistRole
                      ? trackData.tracksArtistRole
                          .map((artist) => artist.artist.name)
                          .join(", ")
                      : "Artist Name"}
                  </h2>
                </div>
                <div className="track-pills">
                  <div>
                    <CustomButton
                      text="Romantic"
                      backgroundColor="rgba(49, 47, 47, 0.51)"
                      textColor="#ffffff"
                      fontFamily="Inter"
                      fontWeight="550"
                      fontSize="12px"
                    />
                  </div>
                  <div>
                    <CustomButton
                      text="Joyful"
                      backgroundColor="rgba(49, 47, 47, 0.51)"
                      textColor="#ffffff"
                      fontFamily="Inter"
                      fontWeight="550"
                      fontSize="12px"
                    />
                  </div>
                  <div>
                    <CustomButton
                      text="Energetic"
                      backgroundColor="rgba(49, 47, 47, 0.51)"
                      textColor="#ffffff"
                      fontFamily="Inter"
                      fontWeight="550"
                      fontSize="12px"
                    />
                  </div>
                  <div>
                    <CustomButton
                      text="Cinematic"
                      backgroundColor="rgba(49, 47, 47, 0.51)"
                      textColor="#ffffff"
                      fontFamily="Inter"
                      fontWeight="550"
                      fontSize="12px"
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* track like share button */}
            <div className="track-action-button">
              <div>
                <CustomButton
                  isIconOnly={true}
                  backgroundColor="rgba(49, 47, 47, 0.51)"
                  borderRadius="50px"
                  iconColor="#fff"
                  icon={<FaRegHeart />}
                  width="34px"
                  height="34px"
                  iconSize="16px"
                />
              </div>
              <div>
                <CustomButton
                  isIconOnly={true}
                  backgroundColor="rgba(49, 47, 47, 0.51)"
                  borderRadius="50px"
                  iconColor="#fff"
                  icon={<FiShare2 />}
                  width="34px"
                  height="34px"
                  iconSize="16px"
                />
              </div>
              <div>
                <CustomButton
                  isIconOnly={true}
                  backgroundColor="rgba(49, 47, 47, 0.51)"
                  borderRadius="50px"
                  iconColor="#fff"
                  icon={<BiAddToQueue />}
                  width="34px"
                  height="34px"
                  iconSize="16px"
                />
              </div>
            </div>
          </div>
        </div>
        {/* tracks about and price section */}
        <div className="inner">
          {/* track about section */}
          <div className="fl about">
            {/* about song container */}
            <div className="about-song-container">
              <h1>About The Song</h1>
              {aboutSong.map((item, index) => (
                <div key={index} className="about-song-item">
                  <h2>{item.title}</h2>
                  {item.description ? (
                    <h3>{item.description}</h3>
                  ) : (
                    <div className="about-song-pills-container">
                      {item.pills.map((pill, pillIndex) => (
                        <div key={pillIndex} className="about-song-pills">
                          {pill}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
          {/* track pricing section */}
          <div className="fl pricing">
            {/* short form videos cards */}
            <div
              className={`pricing-container ${
                openCards[0] ? "open" : "closed"
              }`}
              onClick={() => toggleContainer(0)}
            >
              <div className="pricing-header-container">
                <div className="pricing-header">
                  <div>
                    <h1>Short Form Videos</h1>
                    <h2>For use on Instagram Reels & YouTube Shorts</h2>
                  </div>
                  <div className="pricing-header-price">
                    <span>₹</span>
                    <p>19,999</p>
                  </div>
                </div>
                <div className="pricing-header-icon">
                  <div className="arrow-container">
                    <IoIosArrowDown size={16} color="#01508F" />
                  </div>
                </div>
              </div>
              {openCards[0] && (
                <div className="pricing-details shorts-spacing ">
                  <div className="short-pros-cons-container">
                    <div className="short-pros">
                      {shortPros.pros.map((item, index) => (
                        <div key={index} className="short-pros-item">
                          <span>
                            <IoMdCheckmark size={14} color="#000" />
                          </span>
                          <p>{item}</p>
                        </div>
                      ))}
                    </div>
                    <div className="short-cons">
                      {shortPros.cons.map((item, index) => (
                        <div key={index} className="short-cons-item">
                          <span>
                            <IoIosClose size={18} />
                          </span>
                          <p>{item}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                  <a style={{ color: "#000", opacity: "0.7" }} href="#">
                    Click here for License Terms
                  </a>
                  <div className="shorts-pros-button">
                    <CustomButton
                      justifyContent="space-between"
                      buyArrow={true}
                      fontSize="14px"
                      text="Buy Now"
                      backgroundColor="#C6349B"
                      textColor="#ffffff"
                      fontWeight="550"
                      width="337px"
                      height="39px"
                      borderRadius="50px"
                    />
                    <CustomButton
                      justifyContent="space-between"
                      text="Add to Cart"
                      backgroundColor="#E6E8EA"
                      textColor="#0D0D0D"
                      fontWeight="600"
                      width="337px"
                      height="39px"
                      borderRadius="50px"
                      fontSize="14px"
                      iconColor="#0D0D0D"
                      icon={<RiShoppingCart2Line />}
                    />
                  </div>
                </div>
                // </div>
              )}
            </div>
            {/* buy pack and save more cards */}
            <div className="pricing-saving-band">
              <div
                className={`pricing-container ${
                  openCards[1] ? "open" : "closed"
                }`}
                onClick={() => toggleContainer(1)}
              >
                <div className="pricing-header-container">
                  <div className="pricing-header">
                    <div>
                      <h1>Buy Pack & Save More</h1>
                      <h2>For use on Instagram Reels & YouTube Shorts</h2>
                    </div>
                    {/* <div className="pricing-header-price">
                    <span>₹</span>
                    <p>19,999</p>
                  </div> */}
                  </div>
                  <div className="pricing-header-icon">
                    <div className="arrow-container">
                      <IoIosArrowDown size={16} color="#01508F" />
                    </div>
                  </div>
                </div>
                {openCards[1] && (
                  <div className="pricing-details">
                    <div className="pricing-save-cards-container">
                      {pricingBuyPackCards.map((pack, index) => (
                        <div key={index} className="pricing-save-pack-card">
                          <h1>{pack.title}</h1>
                          <div
                            className="pricing-save-pack-card-img"
                            style={{
                              backgroundImage: `url(${pack.image})`,
                            }}
                          >
                            <div className="pricing-save-card-like-info">
                              <div className="pricing-save-like-icon">
                                <FaRegHeart size={24} color="#fff" />
                              </div>
                              <div className="pricing-save-card-track-total">
                                <p>{pack.totalTracks}</p>
                                <span>TRACKS</span>
                              </div>
                            </div>
                          </div>
                          <div className="pricing-save-pack-price-button">
                            <div className="pricing-save-pack-price-container">
                              <div className="pricing-save-pack-discount-price">
                                {/* <div className="pricing-save-pack-discount-percentage">
                                  -50%
                                </div> */}
                                <div className="pricing-save-pack-discount-amount">
                                  <span>₹</span>
                                  <p>49,999</p>
                                </div>
                              </div>
                              {/* <div className="pricing-save-pack-cost-price">
                                <p>Cost:</p>
                                <span>₹79,000</span>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div>
                      <CustomButton
                        text="View All Packs"
                        backgroundColor="#C6349B"
                        borderRadius="50px"
                        borderColor="none"
                        width="679px"
                        height="39px"
                        textColor="#fff"
                        iconColor="#fff"
                        fontSize="15px"
                        fontFamily="Inter"
                        fontWeight="bold"
                        icon={<IoIosArrowForward />}
                        justifyContent="space-between"
                      />
                    </div>
                  </div>
                )}
              </div>
              EXTRA SAVINGS
              {/* <div className="pricing-saving-band">EXTRA SAVINGS</div> */}
            </div>
            {/* long form videos cards */}
            <div
              className={`pricing-container ${
                openCards[2] ? "open" : "closed"
              }`}
              onClick={() => toggleContainer(2)}
            >
              <div className="pricing-header-container">
                <div className="pricing-header">
                  <div>
                    <h1>Long Form Videos</h1>
                    <h2>
                      For use on DVCs, TVCs, Webshows etc. on YouTube, OTT, and
                      Broadcast
                    </h2>
                  </div>
                  {/* <div className="pricing-header-price">
                    <span>₹</span>
                    <p>19,999</p>
                  </div> */}
                </div>
                <div className="pricing-header-icon">
                  <div className="arrow-container">
                    <IoIosArrowDown size={16} color="#01508F" />
                  </div>
                </div>
              </div>
              {openCards[2] && (
                <div className="pricing-details">
                  <h1 className="pricing-long-form-description">
                    Ready to elevate your brand with the power of music? Share
                    your usage details with us and our team will get back to you
                    with a customized quote. Thank you.
                  </h1>
                  <div>
                    <CustomButton
                      text="Request a Quote"
                      backgroundColor="#C6349B"
                      borderRadius="50px"
                      borderColor="none"
                      width="685px"
                      height="44px"
                      textColor="#fff"
                      iconColor="#fff"
                      fontSize="15px"
                      fontFamily="Inter"
                      fontWeight="bold"
                      icon={<IoIosArrowForward />}
                      justifyContent="space-between"
                    />
                  </div>
                </div>
              )}
            </div>
            {/* benefits section */}
            <div className="pricing-tracks-benifits">
              {tracksBenefits.map((benefit, index) => (
                <div key={index} className="pricingbenefit-container">
                  <div className="benefit-icon-container">
                    <img src={benefit.image} alt={benefit.title} />
                  </div>
                  <h1>{benefit.title}</h1>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {/* other section displayed from here and inside this div only */}
      <div className="outer-full fl trackPacks">
        {/* packs with similiar track container */}
        <div className="packs-container outer-full">
          <div className="inner">
            <h1>Explore Packs with this Track</h1>
            <div className="packs-description-view">
              <h2>Buy this track at a discounted price in these packs</h2>
              <div className="packs-viewall">
                <p>View All</p>
                <p>
                  <IoIosArrowForward color="#373737" />
                </p>
              </div>
            </div>
            <div className="inner">
              <div className="packs-cards-container">
                {packCards.map((pack, index) => (
                  <div key={index} className="pack-card">
                    <h1>{pack.title}</h1>
                    <div
                      className="pack-card-img"
                      style={{
                        backgroundImage: `url(${pack.image})`,
                      }}
                    >
                      <div className="card-like-info">
                        <div className="like-icon">
                          <FaRegHeart size={24} color="#fff" />
                        </div>
                        <div className="card-track-total">
                          <p>{pack.totalTracks}</p>
                          <span>TRACKS</span>
                        </div>
                      </div>
                    </div>
                    <div className="pack-price-button">
                      <div className="pack-price-container">
                        <div className="pack-discount-price">
                          <div className="pack-discount-percentage">-50%</div>
                          <div className="pack-discount-amount">
                            <span>₹</span>
                            <p>49,999</p>
                          </div>
                        </div>
                        <div className="pack-cost-price">
                          <p>Cost:</p>
                          <span>₹79,000</span>
                        </div>
                      </div>
                      <div className="explore-track-button">
                        <CustomButton
                          text={pack.buttonText}
                          backgroundColor="#fff"
                          borderRadius="50px"
                          borderColor="1px solid #C6349B"
                          width="179px"
                          height="44px"
                          textColor="#C6349B"
                          iconColor="#C6349B"
                          fontSize="15px"
                          fontFamily="Inter"
                          fontWeight="bold"
                          icon={<IoIosArrowForward />}
                          justifyContent="space-between"
                        />
                        <CustomButton
                          icon={<RiShoppingCart2Line />}
                          isIconOnly={true}
                          backgroundColor="#C6349B"
                          iconColor="#fff"
                          borderRadius="50px"
                          height="46px"
                          width="46px"
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        {/* explore similar tracks container */}
        <div className="similar-track-cards-container outer-full">
          <div className="inner">
            <h1>Explore Similar Tracks</h1>
            <div className="similar-track-cards">
              {similarTracks.map((track, index) => (
                <div key={index} className="similar-track-card">
                  <div
                    className="similar-track-card-image"
                    style={
                      {
                        //   backgroundImage: `url(${track.image})`,
                      }
                    }
                  ></div>
                  <div className="similar-track-card-text">
                    <div className="similar-track-title">
                      <h1>{track.title}</h1>
                      <h2>{track.description}</h2>
                    </div>
                    <div className="similar-track-price-container">
                      <div className="similar-track-discount-price">
                        <div className="similar-track-discount-amount">
                          <span>₹</span>
                          <p>19,999</p>
                        </div>
                        <div className="similar-track-discount-percentage">
                          50% Off
                        </div>
                      </div>
                      <div className="similar-track-cost-price">
                        <span>₹79,000</span>
                      </div>
                    </div>
                    <div className="similar-explore-track-button">
                      <div>
                        <CustomButton
                          text={track.buttonText}
                          backgroundColor="#fff"
                          borderRadius="50px"
                          borderColor="1px solid #CCCCCC"
                          width="186px"
                          height="37px"
                          textColor="#212328"
                          iconColor="#212328"
                          fontSize="16px"
                          fontFamily="Inter"
                          fontWeight="550"
                          icon={<IoIosArrowForward />}
                          justifyContent="space-between"
                        />
                      </div>
                      <div>
                        <CustomButton
                          icon={<RiShoppingCart2Line />}
                          isIconOnly={true}
                          backgroundColor="#272727"
                          iconColor="#fff"
                          borderRadius="50px"
                          height="37px"
                          width="37px"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrackPage;
